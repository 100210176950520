import React from 'react';

export default function Privacy() {

    return (
        <div>
            <header>
                <h1>Privacy Policy</h1>
            </header>
            <section>
                <h2>Introduction</h2>
                <p>We take the privacy of our website users very seriously. This Privacy Policy outlines how we collect, use, maintain, and disclose information that is collected from users (each, a "User") of our website ("Site").</p>
            </section>

            <section>
                <h2>Information Collection</h2>
                <p>We collect personal identification information from Users when they register on our Site. Users may be asked for, as appropriate, their email address. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personal identification information, but it may prevent them from engaging in certain Site related activities.</p>
            </section>

            <section>
                <h2>How We Use Collected Information</h2>
                <p>The email address Users provide for registration will only be used to send them information and updates pertaining to their account. It may also be used to respond to their inquiries, and/or other requests or questions. If the User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc.</p>
            </section>

            <section>
                <h2>How We Protect Your Information</h2>
                <p>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.</p>
            </section>

            <section>
                <h2>Sharing Your Personal Information</h2>
                <p>We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.</p>
            </section>

            <section>
                <h2>Changes to This Privacy Policy</h2>
                <p>We have the discretion to update this privacy policy at any time. When we do, we will post a notification on the main page of our Site, revise the updated date at the bottom of this page and send you an email. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>
            </section>

            <section>
                <h2>Your Acceptance of These Terms</h2>
                <p>By using this Site, you signify your acceptance of this policy. If you do not agree please navigate away.</p>
            </section>
        </div>
    )
}