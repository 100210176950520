import * as Actions from "./actions"
import * as myLocalStorage from "./../myLocalStorage"
const initialState = {
    userProfile: undefined,
    googleLogin: false,
    score: {
        startTime: null,
        score: []
    },
    validUser: {
        set: false,
        valid: false,
    },
    session: null,
    modelVersion: myLocalStorage.getItem("modelVersion") ? myLocalStorage.getItem("modelVersion") : Actions.MODELVERSION1
};

function setScore(state, newStateArr) {
    let p = { ...state.score };
    let scores = []
    let startTime = new Date();
    for (let i = 0; i < newStateArr.length; i++) {
        let newState = newStateArr[i];
        let jsons = newState.score;
        startTime = newState.startTime;

        if (!jsons) {
            return ({
                ...state,
                score: {
                    startTime: startTime,
                    score: []
                }
            });
        }
        for (let i = 0; i < jsons.length; i++) {
            let j = jsons[i];
            let found = false
            for (let k = 0; k < p.score.length; k++) {
                let s = p.score[k];
                if (s.questionNumber === j.questionNumber) {
                    s.score = j.score; //update old score
                    found = true
                }
            }
            if (!found) {
                scores.push(j)
                p.score.push(j)
            }
        }
    }

    let newVal = {
        startTime: startTime,
        score: [
            ...scores
        ]
    }
    return { ...state, score: newVal }
}

const mainReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_MODEL_VERSION: {
            myLocalStorage.setItem("modelVersion", action.data)
            return {
                ...state,
                modelVersion: action.data
            }
        }
        case Actions.SET_USER: {
            return {
                ...state,
                userProfile: action.data
            }
        }
        case Actions.SET_VALID_USER: {
            let setVal = {
                set: true,
                valid: action.data
            }
            return {
                ...state,
                validUser: setVal
            }
        }
        case Actions.SET_SESSION: {
            return {
                ...state,
                session: action.data
            }
        }
        case Actions.SET_GOOGLE_LOGIN: {
            return {
                ...state,
                googleLogin: action.data
            }
        }
        case Actions.SET_SCORE: {
            return setScore(state, action.data);
        }
        default:
            return state;
    }
}
export default mainReducer
