import * as React from 'react';
// import PropTypes from 'prop-types';
// import Button from '@mui/material/Button';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from '@mui/material/Slider';
import { Typography, Select, MenuItem, IconButton } from '@mui/material';
import * as myLocalStorage from './myLocalStorage'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
export default function SettingDialog(props) {
  const { cb, open } = props;
  const config = myLocalStorage.getItem("voiceConfig");
  const setConfig = config ? JSON.parse(config) : {
    speed: 1,
    voiceIndex: 0
  }
  const [settings, setSettings] = React.useState(setConfig);
  const [voices, setVoices] = React.useState([]);
  const synth = window.speechSynthesis;

  React.useEffect(() => {
    if (voices.length > 0) return
    if (!synth) return;
    synth.onvoiceschanged = getVoices;
    speechSynthesis.addEventListener("voiceschanged", () => {
      getVoices();
    })
    getVoices()
    function getVoices() {
      let voice = synth.getVoices();
      let v = []
      for (let i = 0; i < voice.length; i++) {
        let k = voice[i];
        if (k.localService)
          v.push({
            name: k.name + "," + k.lang,
            index: i,
            item: k
          })
      }
      setVoices(v);
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    myLocalStorage.setItem("voiceConfig", JSON.stringify(settings));
  }, [settings]);
  const handleClose = () => {
    cb(settings);
  };

  function handleSpeed(p) {
    let v = p.target.value;
    setSettings(p => ({
      ...p,
      speed: v
    }))
  }

  function handleChange(x) {
    let v = x.target.value;
    setSettings(p => ({
      ...p,
      voiceIndex: v
    }))
  }

  function getName(v) {
    return v?.name
  }

  function playSound() {
    let v = voices[settings.voiceIndex];
    let speed = settings.speed;
    if (!v) {
      return
    }

    const utterance = new SpeechSynthesisUtterance("Hello, How are you");
    // let voices = window.speechSynthesis.getVoices()
    utterance.voice = v.item;
    utterance.rate = speed;
    utterance.volume = 1;
    // console.log("GET ", voices)
    synth.speak(utterance);

  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
          To subscribe to this website, please enter your email address here. We
          will send updates occasionally.
        </DialogContentText> */}
        {/* <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
        /> */}
        <Typography gutterBottom>Voice Speed</Typography>

        <Slider
          aria-label="Custom marks"
          value={settings.speed}
          step={0.1}
          valueLabelDisplay="auto"
          min={0.1}
          max={2}
          name="speed"
          onChange={handleSpeed}
        />
        {voices.length > 0 && (
          <>
            <Typography gutterBottom>Select Voice</Typography>

            <Select
              labelId="demo-simple-select-label"
              id="voice"
              value={settings.voiceIndex}
              label="Voice"
              onChange={handleChange}
            >
              {voices.map((v) => (
                <MenuItem value={v.index} key={v.index}>{
                  getName(v)
                }

                </MenuItem>

              ))}

            </Select>
            <IconButton onClick={() => playSound()}>
              <PlayCircleIcon />
            </IconButton>
          </>

        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

// SimpleDialog.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   selectedValue: PropTypes.string.isRequired,
// };
