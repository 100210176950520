import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import AdbIcon from '@mui/icons-material/Adb';
import PersonIcon from '@mui/icons-material/Person';
import * as ib from './ibdata.js';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { RemoveScroll } from 'react-remove-scroll';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from "./store/actions"
import AppBar from '@mui/material/AppBar';
import Header from './Header.js';
import GoogleLogin from './GoogleLogin.js';
import { useParams } from 'react-router-dom';
import SpeechToText from './SpeechToText.js';
import InputAdornment from '@mui/material/InputAdornment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { IconButton } from '@mui/material';
import * as myLocalStorage from './myLocalStorage'

const DEFALTMSGS = [
    { from: "bot", text: "Hello. Let me know when you are ready to start. Click the sound icon" },
]

var pendingCreate = false;
function MainBot(props) {
    const [chat, setChat] = React.useState("");
    const el = React.useRef(null);
    const [assistant, setAssistant] = React.useState(null);
    const [chatMsgs, setChatMsgs] = React.useState([]);
    const [busy, setBusy] = React.useState(false);
    const gopen = useSelector((state) => state.googleLogin)
    const dispatch = useDispatch();
    const score = useSelector((state) => state.score)
    const validUser = useSelector((state) => state.validUser)
    const userProfile = useSelector((state) => state.userProfile)
    const session = useSelector((state) => state.session)
    const [chatFinal, setChatFinal] = React.useState("");
    const synth = window.speechSynthesis;

    let { botName, sessionId } = useParams();

    function setSession(data) {
        dispatch(Actions.setSession(data));
    }

    function setScore(data) {
        dispatch(Actions.setScore(data));
    }

    function speechCB(data) {
        if (data?.results?.length > 0) {
            let strSet = chatFinal + " " + data.results;
            setChat(strSet);
            if (data.type === "final") {
                setChatFinal(strSet);
            }
        }
    }
    async function getOldChats() {
        let allChats = []
        let chats = await ib.listChatBySessionSync(sessionId);
        for (let i = 0; i < chats.length; i++) {
            let c = chats[i];
            let msg = ""
            if (c.source === "bot") {
                msg = await processText(c.content, c.timeStamp, false);
            } else {
                msg = {msg: c.content}
            }
            allChats.push({ from: c.source, timeStamp: c.timeStamp, text: msg.msg, correct: msg.correct, isCorrect: msg.isCorrect});
        }
        setChatMsgs(allChats);
    }

    React.useEffect(() => {
        async function startBot() {
            if (pendingCreate) return
            let args = {
                assistant: ib.EXAMINERS_TEXT[botName].bot,
                typeOfExam: ib.EXAMINERS_TEXT[botName].name
                // botName//+ "-test"
            }
            let data = {
                type: "STARTPOST",
                value: args
            }
            setScore({
                startTime: new Date(),
                score: []
            })
            if (userProfile) {
                let sess = await ib.getSession(sessionId);
                if (sess) {
                    let ass = JSON.parse(sess.threadId);
                    setSession(sess);
                    setAssistant(ass);
                    getOldChats();
                    // subs = ib.subscribeToChat(sessionId, gotData);
                    return;
                }
            }
            pendingCreate = true;
            setBusy(true);
            let ass = await ib.chatGPTAPI(data)
            if (!ass) {
                setBusy(false)
                pendingCreate = false;
                return
            }
            let thread = ass.thread;
            let timestamp = ib.timestampToISO(thread.created_at);
            let msg = [{
                ...DEFALTMSGS[0],
                timeStamp: timestamp
            }]
            setChatMsgs(msg);
            setAssistant(ass)

            setBusy(false)
            pendingCreate = false;
            if (userProfile) {
                // find and create session here
                let x = {
                    ...ass.assistant,
                }
                delete x['instructions'];
                let y = {
                    assistant: { ...x },
                    thread: { ...ass.thread }
                }
                let sessArgs = {
                    id: sessionId,
                    botName: botName,
                    assistantId: ass.assistant.id,
                    threadId: JSON.stringify(y),
                    userId: userProfile.id,
                    userProfileSessionsId: userProfile.id
                }
                let sess = await ib.findCreateSession(sessArgs);
                if (sess)
                    setSession(sess);
            }
        }
        if (!assistant && botName && validUser && validUser.set) {
            startBot();
        }
        // eslint-disable-next-line 
    }, [assistant, botName, validUser, userProfile])

    React.useEffect(() => {
        if (!chatMsgs || chatMsgs.length < 15) return
        if (gopen) return
        // dispatch(Actions.setGoogleLogin(true));
    }, [gopen, chatMsgs, dispatch])

    function scrollToBottom() {
        if (!el) return;
        el.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
    function talk(msg) {
        function stripHtml(html) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        }
        if (synth) {
            const utterance = new SpeechSynthesisUtterance(stripHtml(msg.text));
            let config = myLocalStorage.getItem("voiceConfig")
            if (config) {
                let c = JSON.parse(config);
                let voices = window.speechSynthesis.getVoices()
                utterance.voice = voices[c.voiceIndex];
                utterance.volume = 1;
                utterance.rate = c.speed;
            }
            synth.speak(utterance);
        }
    }
    React.useEffect(() => {
        scrollToBottom();
    }, [chatMsgs])

    async function processText(text, timeStamp, addText) {
        let correct = null
        let sp = text.split('\n')
        let started = false
        let lines = ""
        let nechat = []
        let jsons = []
        let isCorrect = undefined

        for (let i = 0; i < sp.length; i++) {
            let line = sp[i]
            if (line.includes("```json")) {
                started = true
                lines = ""
                continue
            }
            if (line.includes("```")) {
                started = false
                try {
                    let json = JSON.parse(lines)
                    if (Array.isArray(json)) {
                        jsons.push(...json)
                    } else
                        jsons.push(json)

                } catch {
                    console.log("ERROR PARSING", lines)
                }
                continue
            }
            if (started) {
                lines += line.split('//')[0] + "\n"
            } else {
                // non json text
                nechat.push(line)
            }
        }
        setScore({
            startTime: score.startTime,
            score: jsons
        });
        let final = nechat.join('\n');

        if (jsons[0]?.correctAnswer) {
            correct = jsons[0]?.correctAnswer;
            isCorrect = jsons[0].score > 0
        }
        if (addText) {
            addChatMsg({ from: "bot", text: final, correct: correct, timeStamp: timeStamp, isCorrect: isCorrect }, text);
            talk({ text: final });
        }

        return {msg: final, correct: correct, isCorrect: isCorrect}
    }

    async function addChatMsg(msg, text) {
        if (session) {

            let chatMsg = {
                sessionId: sessionId,
                content: text,
                source: msg.from,
                timeStamp: msg.timeStamp,
                sessionChatMessagesId: sessionId
            }
            ib.createChatMessage(chatMsg);
        }
        setChatMsgs(p => (
            [
                ...p,
                { ...msg }
            ]
        ))
    }
    async function sendMsg(otext) {
        setBusy(true)
        let ret = await ib.chatGPTAPI({
            type: "ASKQUESTION",
            value: {
                ...assistant,
                userQuestion: otext
            }
        });
        if (!ret) {
            setBusy(false)
            return
        }
        let timestamp = ib.timestampToISO(ret.run.created_at);
        addChatMsg({ from: "user", text: chat, timeStamp: timestamp }, chat);
        let val
        let found = false
        while (!found) {
            await new Promise(r => setTimeout(r, 3000));
            ret = await ib.chatGPTAPI({
                type: "CHECKANSWER",
                value: {
                    ...ret
                }
            });
            if (!ret) {
                setBusy(false)
                return
            }
            //The status of the run, which can be either queued, in_progress, requires_action, cancelling, cancelled, failed, completed, or expired.
            const GOODSTATES = {
                'completed': true,
                'in_progress': true,
                'queued': true
            }
            if (ret?.run?.status) {
                if (!GOODSTATES[ret.run.status]) {
                    setChatMsgs(p => (
                        [
                            ...p,
                            { from: "bot", text: "Sorry the request timed out, can you please say again." }
                        ]
                    ))
                    setBusy(false)
                    return
                }
            }
            if (ret.run.status === "completed" || ret.value) {
                val = ret.value
                found = true
            }
        }
        setBusy(false)
        if (val?.text?.value) {
            let timestamp = ib.timestampToISO(ret?.run?.completed_at);
            processText(val.text.value, timestamp, true)
        }
    }
    function buttonClick() {
        if (chat.length <= 0) return;
        setChat("");
        sendMsg(chat);
    }

    // function delBot() {
    //     if (assistant) {
    //         let data = {
    //             type: "ENDASSISTANT",
    //             value: assistant
    //         }
    //         ib.chatGPTAPI(JSON.stringify(data))
    //     }
    // }
    function TimeRender(msg) {
        let date = msg.timeStamp;
        if (!date) return;
        // return;
        let side = true;
        var f = new Date(date)
        const dt = ib.dateConvert(f)
        var style = { fontSize: 10, color: "#3174F5fa" }
        if (side) {
            style['marginLeft'] = 'auto'
        } else {
            style.color = "#D96758fa"
        }
        return (
            <div style={style}>
                <>
                    {dt}
                </>
                {Boolean(synth) && (
                    <IconButton onClick={() => talk(msg)}>
                        <RecordVoiceOverIcon fontSize="10px" />
                    </IconButton>
                )}
            </div>

        )
    }
    function getText(c) {
        let add = ""
        if (c.correct) {
            let color = "black"
            if (c.isCorrect  === true) {
                color = "green"
            }
            if (c.isCorrect === false) {
                color = "red"
            }
            add = `<span style="color:${color}; font-weight:bold">`+c.correct+"</span><p />"
        }
        let v = add + " Click sound icon to hear the message." 
        return v
    }
    const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return (
        <div className="App">
            <Header />
            <GoogleLogin />

            <div className='mainChat'>
                <RemoveScroll>
                    <div className={mobile ? 'topChatMobile' : 'topChat'}>

                        {chatMsgs.map(c => (
                            <div className='oneMessage'>
                                {c.from === "user" ? (
                                    <>
                                        <PersonIcon className='userIcon' />
                                        <div className='iconMessage'>

                                            <TimeRender {...c} />
                                            <div className={c.from + "oneChat"} dangerouslySetInnerHTML={{ __html: c.text }} />
                                        </div>
                                    </>

                                ) : (
                                    <>
                                        <AdbIcon className='botIcon' />
                                        <div className='iconMessage'>

                                            <TimeRender {...c} />
                                            <div className={c.from + "oneChat"} dangerouslySetInnerHTML={{ __html: getText(c) }} />
                                        </div>
                                    </>
                                )}


                            </div>
                        ))}
                        <div id={'el'} ref={el} sx={{ 'padding': "5px" }} />
                    </div>
                </RemoveScroll>

                <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, background: "white", "textAlign": "center", "margin": "5px" }}>
                    <div className='bottomChat'>

                        {busy ? (
                            <Box sx={{ width: '100%', margin: "10px" }}>
                                <LinearProgress />
                            </Box>
                        ) : (
                            <>
                                <TextField
                                    id="chat"
                                    value={chat}
                                    fullWidth
                                    multiline
                                    autoFocus={mobile ? false : true}
                                    minRows={2}
                                    sx={{ resize: "vertical" }}
                                    onKeyDown={(ev) => {
                                        if (ev.key === 'Enter') {
                                            // Do code here
                                            ev.preventDefault();
                                            buttonClick()
                                        }
                                    }}
                                    onChange={(event) => {
                                        let strSet = event.target.value
                                        setChat(strSet);
                                        setChatFinal(strSet);
                                    }}
                                    InputProps={{
                                        endAdornment: < InputAdornment position="end">
                                            <SpeechToText cb={speechCB} />
                                        </InputAdornment>,
                                        spellCheck: false
                                    }}
                                />
                                <Button className="sendButton" variant="contained" onClick={() => buttonClick()} endIcon={<SendIcon />}>
                                    Send
                                </Button>
                            </>

                        )}

                        {/* <Button className="sendButton" variant="contained" onClick={() => delBot()} endIcon={<SendIcon />}>
                    Del Bot
                </Button> */}
                    </div>
                </AppBar>
            </div>
        </div>
    )
}

export default MainBot;