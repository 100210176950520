/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      email
      content
      createdAt
      updatedAt
      Sessions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      botName
      userId
      assistantId
      threadId
      modelVersion
      createdAt
      updatedAt
      userProfile {
        id
        email
        content
        createdAt
        updatedAt
        __typename
      }
      chatMessages {
        nextToken
        __typename
      }
      userProfileSessionsId
      __typename
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        botName
        userId
        assistantId
        threadId
        modelVersion
        createdAt
        updatedAt
        userProfileSessionsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sessionByUser = /* GraphQL */ `
  query SessionByUser(
    $userId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionByUser(
      userId: $userId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        botName
        userId
        assistantId
        threadId
        modelVersion
        createdAt
        updatedAt
        userProfileSessionsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatMessage = /* GraphQL */ `
  query GetChatMessage($id: ID!) {
    getChatMessage(id: $id) {
      id
      sessionId
      createdAt
      updatedAt
      Session {
        id
        botName
        userId
        assistantId
        threadId
        modelVersion
        createdAt
        updatedAt
        userProfileSessionsId
        __typename
      }
      content
      source
      timeStamp
      sessionChatMessagesId
      __typename
    }
  }
`;
export const listChatMessages = /* GraphQL */ `
  query ListChatMessages(
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sessionId
        createdAt
        updatedAt
        content
        source
        timeStamp
        sessionChatMessagesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const chatBySession = /* GraphQL */ `
  query ChatBySession(
    $sessionId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatBySession(
      sessionId: $sessionId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sessionId
        createdAt
        updatedAt
        content
        source
        timeStamp
        sessionChatMessagesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
