import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import btn from './assets/google.svg';
import { signInWithRedirect } from "aws-amplify/auth";
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

export default function GoogleLogin() {
    const gopen = useSelector((state) => state.googleLogin)
    const user = useSelector((state) => state.userProfile)
    const open = !Boolean(user) && Boolean(gopen);

    const handleClose = () => {
    };

    function GoogleSignIn() {
        return (
            <Button className="googleLogin" onClick={() => signInWithRedirect({ provider: "Google" })}>
                <img src={btn} alt="Google Sign In button"
                    className="googleSignIn"
                />
            </Button>
        );
    }
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
        >
            Please login to continue.
            <GoogleSignIn />
        </Backdrop>
    );
}