import React from 'react';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import AdbIcon from '@mui/icons-material/Adb';
import PersonIcon from '@mui/icons-material/Person';
import * as ib from './ibdata.js';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { RemoveScroll } from 'react-remove-scroll';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from "./store/actions"
import AppBar from '@mui/material/AppBar';
import Header from './Header.js';
import GoogleLogin from './GoogleLogin.js';
import { useParams } from 'react-router-dom';
import SpeechToText from './SpeechToText.js';
import InputAdornment from '@mui/material/InputAdornment';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { IconButton } from '@mui/material';
import * as myLocalStorage from './myLocalStorage'
import useResizeObserver from "use-resize-observer";
const DEFALTMSGS = [
    { from: "bot", text: "Hello. Let me know when you are ready to start." },
    // { from: "bot", text: "Hello. Lots of text to MARs" },
    // { from: "bot", text: "Hello. Let me know when you are ready to start." },
    // { from: "bot", text: "Hello. Lots of text to MARs" },
    // { from: "bot", text: "Hello. Let me know when you are ready to start." },
    // { from: "bot", text: "Hello. Lots of text to MARs" },
    // { from: "bot", text: "Hello. Let me know when you are ready to start." },
    // { from: "bot", text: "Hello. Lots of text to MARs" },
    // { from: "bot", text: "Hello. Let me know when you are ready to start." },
    // { from: "bot", text: "Hello. Lots of text to MARs" },
    // { from: "bot", text: "Hello. Let me know when you are ready to start." },
    // { from: "bot", text: "Hello. Lots of text to MARs" },
]
var pendingCreate = false;
var timer;
function MainBot(props) {
    const [chat, setChat] = React.useState("");
    const el = React.useRef(null);
    const [assistant, setAssistant] = React.useState(null);
    const [chatMsgs, setChatMsgs] = React.useState([]);
    const [busy, setBusy] = React.useState(false);
    const gopen = useSelector((state) => state.googleLogin)
    const dispatch = useDispatch();
    const score = useSelector((state) => state.score)
    const validUser = useSelector((state) => state.validUser)
    const userProfile = useSelector((state) => state.userProfile)
    const session = useSelector((state) => state.session)
    const [chatFinal, setChatFinal] = React.useState("");
    const synth = window.speechSynthesis;
    const modelVersion = useSelector((state) => state.modelVersion)

    const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    const { ref } = useResizeObserver({
        onResize: ({ width, height }) => {
            setHeight(width, height);
        }
    });

    React.useEffect(() => {
        var myTimer;
        // eslint-disable-next-line 
        function setHeightStart(width, height) {
            if (myTimer !== null) {
                clearTimeout(myTimer);
                myTimer = null;
            }
            myTimer = setTimeout(function () {
                let topHeight = document.getElementById('topBar').clientHeight
                let bottomHeight = document.getElementById('bottomBar').clientHeight
                var e1 = document.getElementById("topChat");
                if (!e1) {
                    console.log("OTOP CHAT ", e1)
                    return;
                }
                console.log("HT STYLE", e1.style, e1.clientHeight);

                const MARGIN = mobile ? 80 : 30;
                const viewportHeight = window.innerHeight; //window.visualViewport.height;
                let ht = viewportHeight - (topHeight + bottomHeight + MARGIN);
                if (ht !== e1.clientHeight) {
                    console.log("CHANGE HT", e1.clientHeight, ht)
                    e1.style.height = ht + "px";
                }
                console.log("RESIZE", ht, viewportHeight, topHeight, bottomHeight)
            }, 500);
        }
        // setHeightStart();
        return () => {
            if (myTimer !== null) {
                clearTimeout(myTimer);
                myTimer = null;
            }
        }
        // eslint-disable-next-line
    }, []);
    function setHeight(width, height) {
        if (timer !== null) {
            clearTimeout(timer);
            timer = null;
        }
        timer = setTimeout(function () {
            let bb = document.getElementById('bottomBar');
            if (!bb) return
            let bottomHeight = bb.clientHeight
            var e1 = document.getElementById("dummy");
            if (!e1) return;
            var dumHeight = e1.clientHeight;
            if (bottomHeight > 99 || dumHeight > 0) {
                let ht = (bottomHeight - 99);
                e1.style.minHeight = ht + "px";
                if (!mobile)
                    scrollToBottom();
            }
        }, 300);
    }

    let { botName, sessionId } = useParams();

    function setSession(data) {
        dispatch(Actions.setSession(data));
    }

    function setScore(data) {
        dispatch(Actions.setScore(data));
    }

    function setDefaultChats(timestamp) {
        if (!timestamp)
            timestamp = new Date().toISOString()
        let msg = []
        for (let i = 0; i < DEFALTMSGS.length; i++) {
            msg.push({
                from: DEFALTMSGS[i].from,
                text: DEFALTMSGS[i].text,
                timeStamp: timestamp
            })
        }
        setChatMsgs(msg);
    }

    function speechCB(data) {
        if (data?.results?.length > 0) {
            let strSet = chatFinal + " " + data.results;
            setChat(strSet);
            if (data.type === "final") {
                setChatFinal(strSet);
            }
        }
    }
    async function getOldChats() {
        let allChats = []
        let chats = await ib.listChatBySessionSync(sessionId);
        if (chats.length > 0) {
            let timestamp = new Date().toISOString()
            for (let i = 0; i < DEFALTMSGS.length; i++) {
                allChats.push({
                    from: DEFALTMSGS[i].from,
                    text: DEFALTMSGS[i].text,
                    timeStamp: timestamp
                })
            }
        }
        let scores = []
        for (let i = 0; i < chats.length; i++) {
            let c = chats[i];
            let msg = ""
            if (c.source === "bot") {
                let ret = await processText(c.content, c.timeStamp, false);
                msg = ret.text;
                scores.push(ret.score)
            } else {
                msg = c.content;
            }
            setScore(scores)
            allChats.push({ from: c.source, timeStamp: c.timeStamp, text: msg });
        }
        if (allChats.length > 0)
            setChatMsgs(allChats);
        else
            setDefaultChats(null);
    }

    React.useEffect(() => {
        async function startBot() {
            if (pendingCreate) return
            let args = {
                assistant: ib.EXAMINERS_TEXT[botName].bot,
                typeOfExam: ib.EXAMINERS_TEXT[botName].name
                // botName//+ "-test"
            }
            let data = {
                type: "STARTPOST",
                value: args
            }
            setScore([{
                startTime: new Date(),
                score: []
            }])
            if (userProfile) {
                let sess = await ib.getSession(sessionId);
                if (sess) {
                    // check assistant version
                    let mv = sess.modelVersion ? sess.modelVersion : Actions.MODELVERSION1
                    if (mv !== modelVersion) {
                        dispatch(Actions.setModelVersion(mv));
                        alert("Changing to model version in this chat:" + mv);
                    }
                    let ass = JSON.parse(sess.threadId);
                    setSession(sess);
                    setAssistant(ass);
                    getOldChats();
                    // subs = ib.subscribeToChat(sessionId, gotData);
                    return;
                }
            }
            pendingCreate = true;
            setBusy(true);
            let ass
            if (modelVersion !== Actions.MODELVERSION2) {
                ass = await ib.chatGPTAPI(data)
                if (!ass) {
                    setBusy(false)
                    pendingCreate = false;
                    return
                }
            } else {
                ass = {
                    assistant: {...args},
                    thread: {}
                }
            }
            let thread = ass.thread;
            let timestamp = new Date().toISOString();
            if (modelVersion !== Actions.MODELVERSION2) {
                timestamp = ib.timestampToISO(thread.created_at);
            }
            setDefaultChats(timestamp);
            setAssistant(ass)

            setBusy(false)
            pendingCreate = false;
            if (userProfile) {
                // find and create session here
                let x = {
                    ...ass.assistant,
                }
                delete x['instructions'];
                let y = {
                    assistant: { ...x },
                    thread: { ...ass.thread }
                }
                let sessArgs = {
                    id: sessionId,
                    botName: botName,
                    assistantId: ass.assistant.id,
                    threadId: JSON.stringify(y),
                    userId: userProfile.id,
                    userProfileSessionsId: userProfile.id,
                    modelVersion: modelVersion
                }
                let sess = await ib.findCreateSession(sessArgs);
                if (sess)
                    setSession(sess);
            }
        }
        if (!assistant && botName && validUser && validUser.set) {
            startBot();
        }
        // eslint-disable-next-line 
    }, [assistant, botName, validUser, userProfile])

    React.useEffect(() => {
        if (!chatMsgs || chatMsgs.length < 10) return
        if (gopen) return
        dispatch(Actions.setGoogleLogin(true));
    }, [gopen, chatMsgs, dispatch])

    function scrollToBottom() {
        if (!el) return;
        el.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
    function talk(msg) {
        function stripHtml(html) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        }
        if (synth) {
            const utterance = new SpeechSynthesisUtterance(stripHtml(msg.text));
            let config = myLocalStorage.getItem("voiceConfig")

            if (config) {
                let c = JSON.parse(config);
                let voices = window.speechSynthesis.getVoices()
                utterance.voice = voices[c.voiceIndex];
                utterance.volume = 1;
                utterance.rate = c.speed;
            }
            synth.speak(utterance);
        }
    }
    React.useEffect(() => {
        scrollToBottom();
    }, [chatMsgs])

    async function processText(text, timeStamp, addText) {
        let sp = text.split('\n')
        let started = false
        let lines = ""
        let nechat = []
        let jsons = []

        for (let i = 0; i < sp.length; i++) {
            let line = sp[i]
            if (line.includes("```json")) {
                started = true
                lines = ""
                continue
            }
            if (line.includes("```")) {
                started = false
                try {
                    let json = JSON.parse(lines)
                    if (Array.isArray(json)) {
                        jsons.push(...json)
                    } else
                        jsons.push(json)

                } catch {
                    console.log("ERROR PARSING", lines)
                }
                continue
            }
            if (started) {
                lines += line.split('//')[0] + "\n"
            } else {
                if (line.includes('{"questionNumber"')) {
                    // no start or stop
                    try {
                        let json = JSON.parse(line)
                        jsons.push(json)
                    } catch {

                    }
                    continue
                }
                // non json text
                nechat.push(line)
            }
        }

        let final = nechat.join('\n');
        if (addText) {
            setScore([{
                startTime: score.startTime,
                score: jsons
            }]);
            addChatMsg({ from: "bot", text: final, timeStamp: timeStamp }, text);
        }
        let reval = {
            text: final,
            score: {
                startTime: score.startTime,
                score: jsons
            }
        }
        return reval;
    }

    async function addChatMsg(msg, text) {
        if (session) {

            let chatMsg = {
                sessionId: sessionId,
                content: text,
                source: msg.from,
                timeStamp: msg.timeStamp,
                sessionChatMessagesId: sessionId
            }
            ib.createChatMessage(chatMsg);
        }
        setChatMsgs(p => (
            [
                ...p,
                { ...msg }
            ]
        ))
    }
    async function sendMsg(otext) {
        if (modelVersion === Actions.MODELVERSION2) {
            setBusy(true);
            let oldChats = []
            for (let i = 0; i < chatMsgs.length; i++) {
                let c = chatMsgs[i];
                let from = c.from;
                if (from === "bot") {
                    from = "model"
                }
                oldChats.push({
                    role: from,
                    parts: c.text
                })
            }
        addChatMsg({ from: "user", text: chat, timeStamp: new Date().toISOString() }, chat);
            let ret = await ib.chatGPTAPI({
                type: "MODEL2CHAT",
                value: {
                    assistant: assistant,
                    chat: oldChats,
                    userQuestion: otext
                }
            });
            setBusy(false)
            if (ret?.text) {

                // let timestamp = ib.timestampToISO(ret?.run?.completed_at);
                processText(ret.text, new Date().toISOString(), true)
            }
            return
        }
        setBusy(true)
        let ret = await ib.chatGPTAPI({
            type: "ASKQUESTION",
            value: {
                ...assistant,
                userQuestion: otext
            }
        });
        if (!ret) {
            setBusy(false)
            return
        }
        let timestamp = ib.timestampToISO(ret.run.created_at);
        addChatMsg({ from: "user", text: chat, timeStamp: timestamp }, chat);
        let val
        let found = false
        while (!found) {
            await new Promise(r => setTimeout(r, 3000));
            ret = await ib.chatGPTAPI({
                type: "CHECKANSWER",
                value: {
                    ...ret
                }
            });
            if (!ret) {
                setBusy(false)
                return
            }
            //The status of the run, which can be either queued, in_progress, requires_action, cancelling, cancelled, failed, completed, or expired.
            const GOODSTATES = {
                'completed': true,
                'in_progress': true,
                'queued': true
            }
            if (ret?.run?.status) {
                if (!GOODSTATES[ret.run.status]) {
                    setChatMsgs(p => (
                        [
                            ...p,
                            { from: "bot", text: "Sorry the request timed out, can you please say again." }
                        ]
                    ))
                    setBusy(false)
                    return
                }
            }
            if (ret.run.status === "completed" || ret.value) {
                val = ret.value
                found = true
            }
        }
        setBusy(false)
        if (val?.text?.value) {
            let timestamp = ib.timestampToISO(ret?.run?.completed_at);
            processText(val.text.value, timestamp, true)
        }
    }
    function buttonClick() {
        if (chat.length <= 0) return;
        setChat("");
        sendMsg(chat);
    }

    // function delBot() {
    //     if (assistant) {
    //         let data = {
    //             type: "ENDASSISTANT",
    //             value: assistant
    //         }
    //         ib.chatGPTAPI(JSON.stringify(data))
    //     }
    // }
    function TimeRender(msg) {
        let date = msg.timeStamp;
        if (!date) return;
        // return;
        let side = true;
        var f = new Date(date)
        const dt = ib.dateConvert(f)
        var style = { fontSize: 10, color: "#3174F5fa" }
        if (side) {
            style['marginLeft'] = 'auto'
        } else {
            style.color = "#D96758fa"
        }
        return (
            <div style={style}>
                <>
                    {dt}
                </>
                {Boolean(synth) && (
                    <IconButton onClick={() => talk(msg)}>
                        <RecordVoiceOverIcon fontSize="10px" />
                    </IconButton>
                )}
            </div>

        )
    }
    return (
        <div className="App">
            <Header />
            <GoogleLogin />

            <div className='mainChat'>
                <RemoveScroll>
                    <div id="topChat" className={mobile ? 'topChatMobile' : 'topChat'}>

                        {chatMsgs.map(c => (
                            <div className='oneMessage'>
                                {c.from === "user" ? (
                                    <PersonIcon className='userIcon' />
                                ) : (
                                    <AdbIcon className='botIcon' />
                                )}
                                <div className='iconMessage'>
                                    <TimeRender {...c} />
                                    <div className={c.from + "oneChat"} dangerouslySetInnerHTML={{ __html: c.text }} />
                                </div>

                            </div>
                        ))}
                        <div id="dummy"></div>

                        <div id={'el'} ref={el} sx={{ 'padding': "5px" }} />
                    </div>
                </RemoveScroll>
                <AppBar position="fixed" ref={ref} color="primary" id="bottomBar" sx={{ top: 'auto', bottom: 0, background: "white", "textAlign": "center", "margin": "5px" }}>
                    <div className='bottomChat' >

                        {busy ? (
                            <Box sx={{ width: '100%', margin: "10px" }}>
                                <LinearProgress />
                            </Box>
                        ) : (
                            <>
                                <TextField
                                    id="chat"
                                    value={chat}
                                    fullWidth
                                    multiline
                                    autoFocus={mobile ? false : true}
                                    minRows={2}
                                    sx={{ resize: "vertical" }}
                                    onKeyDown={(ev) => {
                                        if (ev.key === 'Enter') {
                                            // Do code here
                                            ev.preventDefault();
                                            buttonClick()
                                        }
                                    }}
                                    onChange={(event) => {
                                        let strSet = event.target.value
                                        setChat(strSet);
                                        setChatFinal(strSet);
                                    }}
                                    InputProps={{
                                        endAdornment: < InputAdornment position="end">
                                            <SpeechToText cb={speechCB} />
                                        </InputAdornment>
                                    }}
                                />
                                <IconButton className="sendButton" variant="contained" onClick={() => buttonClick()}>
                                    <SendIcon />
                                </IconButton>
                            </>

                        )}

                        {/* <Button className="sendButton" variant="contained" onClick={() => delBot()} endIcon={<SendIcon />}>
                    Del Bot
                </Button> */}
                    </div>
                </AppBar>
            </div>
        </div>
    )
}

export default MainBot;