import React from 'react';
import Header from './Header.js';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GoogleLogin from './GoogleLogin.js';
import AppBar from '@mui/material/AppBar';
import ReactGA from "react-ga4";
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as ib from './ibdata.js';
import { DataGrid } from '@mui/x-data-grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from "./store/actions"
const EXAMINERS = ib.EXAMINERS;

function MainApp() {
    const navigate = useNavigate();
    // get user
    const validUser = useSelector((state) => state.validUser)
    const userProfile = useSelector((state) => state.userProfile)
    const modelVersion = useSelector((state) => state.modelVersion)

    const [sessions, setSessions] = React.useState([]);
    const [dataTable, setDataTable] = React.useState([]);
    const dispatch = useDispatch();

    const columns = [
        { field: 'typePlane', headerName: 'Aircraft', width: 100, editable: false },
        { field: 'examType', headerName: 'Exam', width: 80, editable: false },
        { field: 'rating', headerName: 'Rating', width: 150, renderCell: renderCell, editable: false },
    ]

    function renderCell(p) {
        return (
            <Button variant="contained" size="small" onClick={() => buttonClick(p.id)}>
                {p.formattedValue}
            </Button>
        )
    }
    function buttonClick(k) {
        let botName = k
        let uuid = uuidv4();
        // setBot({ 'botName': EXAMINERS[k][k1] })
        navigate('/bot/' + botName + '/' + uuid);
        ReactGA.event({
            category: "BOTSELECT",
            action: botName,
            label: botName, // optional
        });

    }
    React.useEffect(() => {

        let dt = []
        let bm = {}
        for (let typePlane in EXAMINERS) {
            for (let examType in EXAMINERS[typePlane]) {
                for (let rating in EXAMINERS[typePlane][examType]) {
                    let id = EXAMINERS[typePlane][examType][rating];
                    dt.push({ rating: rating, examType: examType, typePlane: typePlane, id: id })
                    bm[EXAMINERS[typePlane][examType][rating]] = {
                        rating: rating,
                        typePlane: typePlane,
                        examType: examType
                    }
                }
            }
        }
        setDataTable(dt);
    }, []);

    function getSessionName(sess) {
        let bm = {}
        for (let typePlane in EXAMINERS) {
            for (let examType in EXAMINERS[typePlane]) {
                for (let rating in EXAMINERS[typePlane][examType]) {
                    bm[EXAMINERS[typePlane][examType][rating]] = {
                        rating: rating,
                        typePlane: typePlane,
                        examType: examType
                    }
                }
            }
        }
        if (sess.botName in bm) {
            let k = bm[sess.botName]
            let dt = ib.dateConvert(new Date(sess.createdAt));
            return k.examType + "," + k.rating + " [" + dt + "] ";
        }

        return "none"
    }
    function selectSession(sess) {
        let url = "/bot/" + sess.botName + "/" + sess.id;
        navigate(url)
    }
    React.useEffect(() => {
        async function getSession() {
            let sess = await ib.listSessionsByUser(userProfile.id, 5);
            setSessions(sess);
        }
        if (userProfile) {
            getSession();
        }
    }, [userProfile]);

    function modelChange(e) {
        let version = Actions.MODELVERSION1;
        if (e.target.checked) {
            version = Actions.MODELVERSION2;
        }
        ReactGA.event({
            category: "SETVERSION",
            action: version,
            label: version, // optional
        });
        dispatch(Actions.setModelVersion(version));
    }

    function ChooseBot() {
        return (
            <>
                <div className='examiners'>
                    {/* <Typography variant="h4" gutterBottom>
                        Sorry we have hit the BETA limit for today, come back tomorrow.
                    </Typography> */}
                    <Typography variant="h4" gutterBottom>
                        Select your AI examiner
                        <FormGroup>
                            <FormControlLabel 
                                control={<Switch defaultChecked 
                                    onChange={modelChange} 
                                    checked={modelVersion === Actions.MODELVERSION2}
                                     size="small"/>} label="Faster (but cursory) Bot" />
                        </FormGroup>
                    </Typography>
                    <div className='examGrid'>
                        <DataGrid rows={dataTable} columns={columns} />
                    </div>
                    <div className='loginMessage'>

                        <Typography variant="body" >
                            Please login to avoid session to reset after 5 questions.
                            <ul>
                                <li>If you login, you can see and share your past sessions</li>
                                <li>Don't use this on the facebook app. Open your browser and go to https://www.paw1.net</li>
                            </ul>
                        </Typography>
                    </div>
                    {sessions && sessions.length > 0 && (
                        <div className='userSessions'>
                            <hr />
                            <Typography variant="h5" gutterBottom>
                                Previous Sessions
                            </Typography>
                            {sessions.map(u => (
                                <Button variant="contained" color="secondary" sx={{ "marginRight": "10px", marginTop: "10px" }}
                                    key={u.id} onClick={() => selectSession(u)}>
                                    {getSessionName(u)}
                                </Button>
                            ))}
                        </div>
                    )}
                </div>
                <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, background: "white", "textAlign": "center", "margin": "5px" }}>
                    <div className="bottomEmail">
                        <a href="mailto:feedback@paw1.net" sx={{ "textAlign": "center" }}>
                            feedback@paw1.net
                        </a>
                    </div>
                    <div className="privacyTos">
                        <a href="/privacy" sx={{ "textAlign": "center", "padding-right": "10px" }}>
                            Privacy
                        </a>
                        <a href="/terms" sx={{ "textAlign": "center" }}>
                            Terms
                        </a>
                    </div>
                </AppBar>
            </>

        )
    }
    return (
        <div className="App">
            <Header />
            <GoogleLogin />
            {validUser && validUser.set && (
                <ChooseBot />
            )}
        </div>
    );
}

export default MainApp;
