import React from 'react';

export default function Terms() {

    return (
        <div>
            <h2>TERMS OF SERVICE</h2>

            <p>Please read these terms of service ("terms", "terms of service") carefully before using https://paw1.net website ("website", "service") operated by paw1.net ("us", 'we", "our").</p>

            <h3>Conditions of Use</h3>

            <p>By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to leave the website accordingly. paw1.net only grants use and access of this website, its products, and its services to those who have accepted its terms.</p>

            <h3>Copyright</h3>

            <p>Content published on this website (digital downloads, images, texts, graphics, logos) is the property of paw1.net and/or its content creators and protected by international copyright laws. The entire compilation of the content found on this website is the exclusive property of paw1.net, with copyright authorship for this compilation by paw1.net.</p>

            <h3>Communications</h3>

            <p>The entire communication with us is electronic. Every time you send us an email or visit our website, you are going to be communicating with us. You hereby consent to receive communications from us. If you subscribe to the news on our website, you are going to receive regular emails from us. We will continue to communicate with you by posting news and notices on our website and by sending you emails. You also agree that all notices, disclosures, agreements and other communications we provide to you electronically meet the legal requirements that such communications be in writing.</p>

            <h3>Applicable Law</h3>

            <p>By visiting this website, you agree that the laws of California, without regard to principles of conflict laws, will govern these terms of service, or any dispute of any sort that might come between paw1.net and you, or its business partners and associates.</p>

            <h3>Use of AI Instructor</h3>

            <p>Our website uses an AI instructor to guide users. This AI instructor is based on artificial intelligence and while we strive to provide accurate and helpful instructions, we make no guarantees or warranties about the validity, accuracy, correctness, reliability, quality, stability, completeness or up-to-dateness of any information provided by the AI instructor. Any reliance on such information is strictly at the user's own risk. paw1.net will not be liable for any errors or omissions in the AI instructor's information or for any actions taken based on its outputs.</p>

            <h3>Limitation of Liability</h3>

            <p>paw1.net, its directors, employees, partners, agents, suppliers, or affiliates, shall not be liable for any loss or damage, direct or indirect, incidental, special, consequential or punitive damages, including without limitation, economic loss, loss or damage to electronic media or data, goodwill, or other intangible losses, resulting from
                (i) your access to or use of the service;
                (ii) your inability to access or use the service;
                (iii) any conduct or content of any third-party on or related to the service;
                (iv) any content obtained from or through the service; and
                (v) the unauthorized access to, use of or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other claim in law, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

            <h3>Dispute Resolution</h3>

            <p>The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of California without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of California. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the state and federal courts located in California, United States, and you hereby submit to the personal jurisdiction of such courts.</p>

            <h3>Changes to Terms</h3>

            <p>We reserve the right to modify these terms from time to time at our sole discretion. Therefore, you should review this page periodically. When we change the Terms in a material manner, we will notify you that material changes have been made to the Terms. Your continued use of the Website or our service after any such change constitutes your acceptance of the new Terms. If you do not agree to any of these terms or any future version of the Terms, do not use or access (or continue to access) the website or the service.</p>
        </div>
    )
}