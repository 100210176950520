
var LOCAL_STORAGE = {}

export function getItem(name) {
    try {
        return localStorage.getItem(name)
    } catch {
        // console.error("cannot get storage", name, LOCAL_STORAGE[name])
        return LOCAL_STORAGE[name];
    }
}

export function setItem(name, value) {
    try {
        return localStorage.setItem(name, value) 
    } catch {
        if (!(name in LOCAL_STORAGE) && (name === "mystoreID")) {
        }
        LOCAL_STORAGE[name] = value;
        // console.error("cannot set storage:", name, "value:", value)
        return value;
    }
}


export function removeItem(name) {
    try {
        return localStorage.removeItem(name) 
    } catch {
        delete LOCAL_STORAGE[name];
        // console.error("cannot remove storage", name) 
        return null 
    }
}

export function clear() {
    try {
        return localStorage.clear() 
    } catch {
        LOCAL_STORAGE = {};
        // console.error("cannot clear") 
        return null 
    }
}
