import React from 'react';
import Typography from '@mui/material/Typography';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery50Icon from '@mui/icons-material/Battery50';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import { useSelector } from 'react-redux';

// var myClock;

function Score(props) {
    const score = useSelector((state) => state.score)

    // const [clock, setClock] = React.useState({ timeSpent: "00:00:00" });
    const [scores, setScores] = React.useState(
        {
            percentage: 0,
            colors: {
                red: 0,
                yellow: 0,
                green: 0
            }
        }
    )
    // React.useEffect(() => {
    //     if (myClock) {
    //         clearInterval(myClock);
    //     }
    //     if (!score.startTime) return;

    //     myClock = setInterval(setTime, 1000)
    //     function setTime() {
    //         function pad2(number) {
    //             return (number < 10 ? '0' : '') + number
    //         }

    //         let start = score.startTime;
    //         var cd = start;
    //         var ce = new Date()
    //         var delta = (ce - cd) / 1000

    //         var hours = Math.floor(delta / 3600);
    //         delta -= hours * 3600;
    //         var minutes = Math.floor(delta / 60) % 60;
    //         delta -= minutes * 60;
    //         var seconds = Math.floor(delta % 60)
    //         var formated1 = pad2(hours) + ":" + pad2(minutes) + ":" + pad2(seconds)//moment().duration(elapsedDuration).format("HH:MM:SS");
    //         setClock({ timeSpent: formated1 })

    //     }
    //     return () => {
    //         if (myClock) {
    //             clearInterval(myClock);
    //             myClock = null
    //         }
    //     }
    // }, [score.startTime]);

    React.useEffect(() => {
        if (!score.score || score.score.length === 0) return
        let totalPoints = 0;
        let maxPoints = score.score.length * 2;
        let colors = { red: 0, yellow: 0, green: 0 }
        for (let i = 0; i < score.score.length; i++) {
            let s = score.score[i]
            totalPoints += s.score;
            if (s.score === 0) {
                colors.red += 1
            }
            if (s.score === 1) {
                colors.yellow += 1
            }
            if (s.score === 2) {
                colors.green += 1
            }
        }
        let percentage = totalPoints / maxPoints * 100;
        setScores(
            {
                colors: colors,
                percentage: Math.round(percentage)
            }
        )
    }, [score.score])
    return (
        <>
            <div className="scoreMain">
                {/* <div className='timer'>
                        <Typography variant="body" gutterBottom>
                            {clock.timeSpent}
                        </Typography>
                    </div> */}
                <div className='scorePercent'>
                    <Typography variant="body" gutterBottom>
                        {scores.percentage}%
                    </Typography>
                </div>
                <div className='scoreColor'>
                    <div className='scoreColorLine'>
                        <BatteryFullIcon sx={{ color: "#aad484" }} /> {scores.colors.green}
                    </div>
                    <div className='scoreColorLine'>
                        <Battery50Icon sx={{ color: "#fdd872" }} /> {scores.colors.yellow}
                    </div>
                    <div className='scoreColorLine'>
                        <Battery0BarIcon sx={{ color: "#dfa1d1" }} /> {scores.colors.red}
                    </div>
                </div>

            </div>
        </>
    )
}

export default Score;
