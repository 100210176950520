export const SET_USER = "[MAIN] SET_USER";
export const SET_GOOGLE_LOGIN = "[MAIN] SET_GOOGLE_LOGIN";
export const SET_SCORE = "[MAIN] SET_SCORE";
export const SET_SESSION = "[MAIN] SET_SESSION";
export const SET_VALID_USER = "[MAIN] SET_VALID_USER";
export const SET_MODEL_VERSION = "[MAIN] SET_MODEL_VERSION";
export const MODELVERSION1 = "MODEL_VERSION1";
export const MODELVERSION2 = "MODEL_VERSION2";

export function setModelVersion(data) {
    return {
        type: SET_MODEL_VERSION,
        data
    }
}
export function setUser(data) {
    return {
        type: SET_USER,
        data
    }
}

export function setGoogleLogin(data) {
    return {
        type: SET_GOOGLE_LOGIN,
        data
    }
}

export function setScore(data) {
    return {
        type: SET_SCORE,
        data
    }
}

export function setSession(data) {
    return {
        type: SET_SESSION,
        data
    }
}

export function setValidUser(data) {
    return {
        type: SET_VALID_USER,
        data
    }
}