import React from 'react';
import Header from './Header.js';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GoogleLogin from './GoogleLogin.js';
import AppBar from '@mui/material/AppBar';
import ReactGA from "react-ga4";
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import * as ib from './ibdata.js';

const EXAMINERS = ib.STUDENT_TESTERS;

function StudyApp() {
    const navigate = useNavigate();
    // get user
    const validUser = useSelector((state) => state.validUser)
    const userProfile = useSelector((state) => state.userProfile)
    const [sessions, setSessions] = React.useState([]);

    function getSessionName(sess) {
        for(let k in EXAMINERS) {
            for (let k1 in EXAMINERS[k]) {
                let n = EXAMINERS[k][k1];
                if (n === sess.botName) {
                    let dt = ib.dateConvert(new Date(sess.createdAt));
                    let f = k.replace(' (Multiple Choice)', '');
                    return k1 + " ["+f +"] " + dt;
                }
            }
        }
        return "none"
    }
    function selectSession(sess) {
        let url = "/sbot/" + sess.botName + "/" + sess.id;
        navigate(url)
    }
    React.useEffect(() => {
        async function getSession() {
            let sess = await ib.listSessionsByUser(userProfile.id, 5);
            setSessions(sess);
        }
        if (userProfile) {
            getSession();
        }
    }, [userProfile]);
    function ChooseBot() {
        function buttonClick(k, k1) {
            let botName = EXAMINERS[k][k1];
            let uuid = uuidv4();
            // setBot({ 'botName': EXAMINERS[k][k1] })
            navigate('/sbot/' + botName + '/' + uuid);
            ReactGA.event({
                category: "BOTSELECT",
                action: EXAMINERS[k][k1],
                label: EXAMINERS[k][k1], // optional
            });

        }
        return (
            <>
                <div className='examiners'>
                    {/* <Typography variant="h4" gutterBottom>
                        Sorry we have hit the BETA limit for today, come back tomorrow.
                    </Typography> */}
                    <Typography variant="h4" gutterBottom>
                        Select your examiner
                    </Typography>
                    <div className='examGrid'>
                        <Grid container spacing={3}>
                            {Object.keys(EXAMINERS).map((k) => (
                                <Grid key={k} sx={{ background: "#f4f8fd", margin: "15px", borderRadius: "10px" }}>
                                    <Typography variant="h5" gutterBottom>
                                        {k}
                                    </Typography>
                                    <Grid container spacing={3}>
                                        {Object.keys(EXAMINERS[k]).map((k1) => (

                                            <Grid xs="auto" key={k1}>
                                                <Button variant="contained" name={k1} onClick={() => buttonClick(k, k1)}>
                                                    {k1}
                                                </Button>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>

                            ))}
                        </Grid>
                    </div>
                    <div className='loginMessage'>

                        <Typography variant="body" >
                            Please login to avoid session to reset after 5 questions.
                            <ul>
                                <li>If you login, you can see and share your past sessions</li>
                                <li>Don't use this on the facebook app. Open your browser and go to https://www.paw1.net</li>
                            </ul>
                        </Typography>
                    </div>
                    {sessions && sessions.length > 0 && (
                        <div className='userSessions'>
                            <hr />
                            <Typography variant="h5" gutterBottom>
                                Previous Sessions
                            </Typography>
                            {sessions.map(u => (
                                <Button variant="contained" color="secondary" sx={{"marginRight": "10px", marginTop: "10px"}}
                                    key={u.id} onClick={()=>selectSession(u)}>
                                    {getSessionName(u)}
                                </Button>
                            ))}
                        </div>
                    )}
                </div>
                <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, background: "white", "textAlign": "center", "margin": "5px" }}>
                    <div className="bottomEmail">
                        <a href="mailto:feedback@paw1.net" sx={{ "textAlign": "center" }}>
                            feedback@paw1.net
                        </a>
                    </div>
                    <div className="privacyTos">
                        <a href="/privacy" sx={{ "textAlign": "center", "padding-right": "10px" }}>
                            Privacy
                        </a>
                        <a href="/terms" sx={{ "textAlign": "center" }}>
                            Terms
                        </a>
                    </div>
                </AppBar>
            </>

        )
    }
    return (
        <div className="App">
            <Header />
            <GoogleLogin />
            {validUser && validUser.set && (
                <ChooseBot />
            )}
        </div>
    );
}

export default StudyApp;
