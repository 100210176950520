import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useSelector, useDispatch } from 'react-redux';
import { signOut } from "aws-amplify/auth";
import * as Actions from "./store/actions"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Score from './Score';
import SettingDialog from './SettingDialog';

function ResponsiveAppBar() {
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const user = useSelector((state) => state.userProfile)
    const dispatch = useDispatch();
    const [settings, setSettings] = React.useState({ open: false });

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    function logout(isLogout) {
        handleCloseUserMenu();
        if (isLogout) {
            signOut();
        } else {
            dispatch(Actions.setGoogleLogin(true));
        }
    }

    function settingsClick() {
        handleCloseUserMenu();
        setSettings({ open: true, cb: done });
        function done(e) {
            setSettings({open: false});
        }
    }
    return (
        <>
            <AppBar id="topBar" position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                alignItems: 'center'
                            }}
                        >
                            <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />

                            paw1
                        </Typography>

                        <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            paw1
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: 'flex' }}>
                            <Score />
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <AccountCircleIcon sx={{ color: "white" }} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem onClick={() => settingsClick()}>
                                    <Typography textAlign="center">Settings</Typography>
                                </MenuItem>
                                {!Boolean(user) ? (
                                    <MenuItem onClick={() => logout(false)}>
                                        <Typography textAlign="center">Login</Typography>
                                    </MenuItem>
                                ) : (
                                    <MenuItem onClick={() => logout(true)}>
                                        <Typography textAlign="center">LogOut</Typography>
                                    </MenuItem>
                                )}

                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            {settings.open && (<SettingDialog {...settings} />)}
        </>
    );
}
export default ResponsiveAppBar;
