import React from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import IconButton from '@mui/material/IconButton';

export default function SpeechToText(props) {
    const {
        interimTranscript,
        finalTranscript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();
    const startListening = () => SpeechRecognition.startListening({ continuous: true });

    function buttonDown() {
        resetTranscript();
        startListening();
    }

    React.useEffect(() => {
        if (interimTranscript.length > 0) {
            props.cb({ type: "inter", results: interimTranscript });
        }
    }, [interimTranscript, props]);

    React.useEffect(() => {
        if (finalTranscript.length > 0) {
            props.cb({ type: "final", results: finalTranscript });
            resetTranscript();
        }
        
    }, [finalTranscript, props, resetTranscript]);

    function buttonUp() {
        SpeechRecognition.stopListening();
    }
    function speechOnOff() {
        if (listening) {
            buttonUp();
        } else {
            buttonDown();
        }
    }
    return (
        <>
            {browserSupportsSpeechRecognition ? (
                <IconButton
                    aria-label="SpeechOn"
                    onClick={speechOnOff}
                    // onMouseDown={buttonDown}
                    // onMouseUp={buttonUp}
                    // onTouchStart={buttonDown}
                    // onTouchEnd={buttonUp}
                    edge="end"
                >
                    {/* {interimResult && <>{interimResult}</>} */}
                    {listening ? <MicOffIcon /> : <MicIcon />}
                </IconButton>
            ) : (
                <></>
            )}
        </>
        // <div>
        //   <p>Microphone: {listening ? 'on' : 'off'}</p>
        //   <button onClick={SpeechRecognition.startListening}>Start</button>
        //   <button onClick={SpeechRecognition.stopListening}>Stop</button>
        //   <button onClick={resetTranscript}>Reset</button>
        //   <p>{transcript}</p>
        // </div>
    );
};
