import React from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import MainApp from './MainApp';
import StudyApp from './StudyApp';
import StudyBot from './StudyBot';

import ReactGA from "react-ga4";
import Privacy from './Privacy';
import Terms from './Terms';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainBot from './MainBot';
import { Hub } from 'aws-amplify/utils';
import { getCurrentUser, fetchUserAttributes } from "aws-amplify/auth";
import * as Actions from "./store/actions"
import { useDispatch, useSelector } from 'react-redux';
import * as ib from './ibdata';

ReactGA.initialize("G-DG6KJN70NB");

function App() {
  const dispatch = useDispatch();
  const validUser = useSelector((state) => state.validUser)

  async function getUser() {
    try {
      const x = await getCurrentUser();
      const userAttributes = await fetchUserAttributes();
      const token = {
        ...x,
        attr: userAttributes
      }
      let userArgs = {
        id: token.username,
        email: token.attr.email,
        content: JSON.stringify(token.attr)
      }
      let userProfile = await ib.findCreateUser(userArgs)
      dispatch(Actions.setUser(userProfile));
      dispatch(Actions.setValidUser(true));

    } catch (err) {
      dispatch(Actions.setValidUser(false));
      console.log(err);
    }
  }
  //listen for sign in + out events, if neither are happening check if user exists 
  React.useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signIn') {
        return getUser();
      }
      if (payload.event === 'signOut') {
        dispatch(Actions.setUser(null));
      }
    });
    getUser();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {validUser && validUser.set && (

        <Router>
          <Routes>
            <Route exact path='/' element={< MainApp />}></Route>
            <Route exact path='/privacy' element={< Privacy />}></Route>
            <Route exact path='/terms' element={< Terms />}></Route>
            <Route exact path='/bot/:botName/:sessionId' element={< MainBot />}></Route>
            <Route exact path='/sbot/:botName/:sessionId' element={< StudyBot />}></Route>
            <Route exact path='/study' element={< StudyApp />}></Route>

          </Routes>
        </Router>
      )}

    </>

  );
}

export default App;
