import { post } from 'aws-amplify/api';
import { generateClient } from 'aws-amplify/api';
import * as autoQuery from './graphql/queries'
import * as autoMutation from './graphql/mutations'
import * as autoSub from './graphql/subscriptions'

export const STUDENT_TESTERS = {
    "Spelling Bee": {
        "5th Grade Spelling Bee": "grade5Bee",
        "6th Grade Spelling Bee": "grade6Bee"
    }
}
export const EXAMINERS = {
    "Airplane": {
        "Oral": {
            'Private': 'privatePilotDPE',
            'Instrument': 'instrumentPilotDPE',
            'Commercial': 'commercialPilotDPE',
            'Multi Engine ': "multi",
            'CFI': 'cfi',
            'CFII': 'cfii',
            'MEI': 'mei',
            "ATP": "atp",
            "A&P General": "amp",
            "A&P Airframe": "ampairframe",
            "A&P PowerPlant": "amppowerplant",
            'Flight Dispatcher': 'dispatch'
        },
        "Written": {
            'Private': 'privatePilotWritten',
            'Instrument': 'instrumentPilotWritten',
            'Commercial': 'commercialPilotWritten',
        }
    },
    "Rotor": {
        "Oral": {
            'Private': 'rotorRrivatePilotDPE',
            'Instrument': 'rotorInstrumentPilotDPE',
            'Commercial': 'rotorCommercialPilotDPE',
            'CFI': 'rotorCfi',
            'CFII': 'rotorCfii'
        }
    }
}

export const EXAMINERS_TEXT = {
    'privatePilotDPE': { name: 'Private Pilot Oral Exam', bot: 'dpe' },
    'instrumentPilotDPE': { name: 'Instrument Rating Oral Exam', bot: 'dpe' },
    'commercialPilotDPE': { name: 'Commercial Pilot Oral Exam', bot: 'dpe' },
    'multi': { name: 'Multi Engine Rating Oral Exam', bot: 'dpe' },
    'cfi': { name: 'Certified Flight Instructor (CFI) Oral Exam', bot: 'dpe' },
    'cfii': { name: 'Certified Flight Instructor - Instrument (CFII) Oral Exam', bot: 'dpe' },
    'mei': { name: 'Certified Multi-Engine Instructor (MEI) Oral Exam', bot: 'dpe' },
    'atp': { name: 'Airline Transport Pilot Oral Exam', bot: 'dpe' },
    'amp': { name: 'Airframe & Powerplant Mechanics - General Oral Exam, use "FAA-H-8083-30B, Aviation Maintenance Technician Handbook – General"', bot: 'amp' },
    'ampairframe': { name: 'Airframe & Powerplant Mechanics - Airframe Oral Exam, use "FAA-H-8083-31B, Aviation Maintenance Technician Handbook – Airframe"', bot: 'amp' },
    'amppowerplant': { name: 'Airframe & Powerplant Mechanics - Power Plant Oral Exam, use "FAA-H-8083-32B, Aviation Maintenance Technician Handbook – Powerplant "', bot: 'amp' },
    'privatePilotWritten': { name: 'Please start my written exam.', bot: 'privatePilotWritten' },
    'instrumentPilotWritten': { name: 'Please start my written exam.', bot: 'instrumentPilotWritten' },
    'commercialPilotWritten': { name: 'Please start my written exam.', bot: 'commercialPilotWritten' },

    'rotorRrivatePilotDPE': { name: 'Private Pilot Oral Exam for Rotor Craft (Helicopter)', bot: 'dpe' },
    'rotorInstrumentPilotDPE': { name: 'Instrument Rating Oral Exam for Rotor Craft (Helicopter)', bot: 'dpe' },
    'rotorCommercialPilotDPE': { name: 'Commercial Pilot Oral Exam for Rotor Craft (Helicopter)', bot: 'dpe' },
    'rotorCfi': { name: 'Certified Flight Instructor (CFI) Oral Exam for Rotor Craft (Helicopter)', bot: 'dpe' },
    'rotorCfii': { name: 'Certified Flight Instructor - Instrument (CFII) Oral Exam for Rotor Craft (Helicopter)', bot: 'dpe' },

    'dispatch': { name: 'Flight Dispatcher (ADX) Oral Exam', bot: 'dpe' },
    'grade6Bee': { name: '6th Grade Spelling bee exam', bot: 'bee' },
    'grade5Bee': { name: '5th Grade Spelling bee exam', bot: 'bee' }


}

const client = generateClient();

export async function getUserProfile(userId) {
    const result = await client.graphql({
        query: autoQuery.getUserProfile,
        variables: {
            id: userId
        }
    });
    let user = result?.data?.getUserProfile;
    return user
}

export async function createUserProfile(args) {
    let result = null
    try {
        result = await client.graphql({
            query: autoMutation.createUserProfile,
            variables: {
                input: args
            }
        });
    } catch {
        console.log("USER CREATE FAILED");
    }
    if (result === null) {
        try {
            let user = await getUserProfile(args.id);
            if (user) return user;
        } catch {
            console.log("DUP USER , return null");
            return null
        }
    }
    return result?.data?.createUserProfile;
}

export async function findCreateUser(args) {
    let user = await getUserProfile(args.id);
    if (user) {
        return user;
    }
    user = await createUserProfile(args);
    return user;
}


export async function getSession(id) {
    const result = await client.graphql({
        query: autoQuery.getSession,
        variables: {
            id: id
        }
    });
    let user = result?.data?.getSession;
    return user
}

export async function createSession(args) {
    let result = null
    try {
        result = await client.graphql({
            query: autoMutation.createSession,
            variables: {
                input: args
            }
        });
    } catch {
        console.log("DUP SESSION");
        // lets try to get it again
    }
    if (!result) {
        try {
            let user = await getSession(args.id);
            if (user) return user;
        } catch {
            console.log("DUP SESSION , return null");
            return null
        }
    }
    return result?.data?.createSession;
}

export async function findCreateSession(args) {
    let user = await getSession(args.id);
    if (user) {
        return user;
    }
    user = await createSession(args);
    return user;
}

export async function createChatMessage(args) {
    const result = await client.graphql({
        query: autoMutation.createChatMessage,
        variables: {
            input: args
        }
    });
    return result?.data?.createChatMessage;
}

// https://docs.amplify.aws/javascript/build-a-backend/graphqlapi/subscribe-data/
export async function subscribeToChat(sessionId, cb) {
    const createSub = client
        .graphql({
            query: autoSub.subscribeToMyChat,
            variables: { sessionId: sessionId }
        })
        .subscribe({
            next: ({ data }) => {
                console.log(data);
                cb(data);
            },
            error: (error) => console.warn(error)
        });
    return createSub;
}
/****
 * 
 */
export async function getSessionSync(sessionId) {
    const result = await client.graphql({
        query: autoQuery.getSession,
        variables: {
            id: sessionId
        }
    });
    return result;
}

export async function listChatBySessionSync(sessionId) {
    var allList = [];
    var nt = null
    do {
        const cmd = { limit: 100, nextToken: nt, sessionId: sessionId }
        const res = await client.graphql({
            query: autoQuery.chatBySession,
            variables: cmd
        });

        const dat = res.data.chatBySession;
        allList = [...allList, ...dat.items]

        nt = dat.nextToken
    } while (nt != null)
    return allList
}

export async function listSessionsByUser(userId, limit) {
    var allList = [];
    var nt = null
    do {
        const cmd = { limit: limit, nextToken: nt, userId: userId, sortDirection: "DESC" }
        const res = await client.graphql({
            query: autoQuery.sessionByUser,
            variables: cmd
        });

        const dat = res.data.sessionByUser;
        allList = [...allList, ...dat.items]
        if (allList.length === limit) break;
        nt = dat.nextToken
    } while (nt != null)
    return allList
}

// export async function listSessionSync(usedId) {
//     var allList = [];
//     var nt = null
//     do {
//         const cmd = { limit: 100, nextToken: nt, sessionId: sessionId }
//         const res = await API.graphql(graphqlOperation(autoQuery.queryByFoundUser, cmd))
//         const dat = res.data.queryByFoundUser
//         allList = [...allList, ...dat.items]

//         nt = dat.nextToken
//     } while (nt != null)
//     return allList
// }

export async function postData(data) {
    const response = await fetch("http://localhost:5001/", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: data
    })
    var r = await response.json();
    return r
}

export async function chatGPTExtApt(data) {
    let response = null
    try {
        const restOperation = post({
            apiName: 'aiapi',
            path: '/',
            options: {
                body: data
            }
        });
        response = await restOperation.response;
        if (!response) return null
        response = await response.body.json()
    } catch (e) {
        console.log('POST call failed: ', e);
    }
    return response
}

export async function chatGPTAPI(data) {
    return await chatGPTExtApt(data);
    // return await postData(JSON.stringify(data));
}

export function timestampToISO(ts) {
    return new Date(ts * 1000).toISOString();
}
export function dateConvert(date) {
    var lang = window.navigator.userLanguage || window.navigator.language;
    var options = {
        // weekday: "short",
        month: "2-digit",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    };

    return date.toLocaleString(lang, options);
}