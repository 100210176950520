/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      email
      content
      createdAt
      updatedAt
      Sessions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      email
      content
      createdAt
      updatedAt
      Sessions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      email
      content
      createdAt
      updatedAt
      Sessions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      botName
      userId
      assistantId
      threadId
      modelVersion
      createdAt
      updatedAt
      userProfile {
        id
        email
        content
        createdAt
        updatedAt
        __typename
      }
      chatMessages {
        nextToken
        __typename
      }
      userProfileSessionsId
      __typename
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      botName
      userId
      assistantId
      threadId
      modelVersion
      createdAt
      updatedAt
      userProfile {
        id
        email
        content
        createdAt
        updatedAt
        __typename
      }
      chatMessages {
        nextToken
        __typename
      }
      userProfileSessionsId
      __typename
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      botName
      userId
      assistantId
      threadId
      modelVersion
      createdAt
      updatedAt
      userProfile {
        id
        email
        content
        createdAt
        updatedAt
        __typename
      }
      chatMessages {
        nextToken
        __typename
      }
      userProfileSessionsId
      __typename
    }
  }
`;
export const createChatMessage = /* GraphQL */ `
  mutation CreateChatMessage(
    $input: CreateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    createChatMessage(input: $input, condition: $condition) {
      id
      sessionId
      createdAt
      updatedAt
      Session {
        id
        botName
        userId
        assistantId
        threadId
        modelVersion
        createdAt
        updatedAt
        userProfileSessionsId
        __typename
      }
      content
      source
      timeStamp
      sessionChatMessagesId
      __typename
    }
  }
`;
export const updateChatMessage = /* GraphQL */ `
  mutation UpdateChatMessage(
    $input: UpdateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    updateChatMessage(input: $input, condition: $condition) {
      id
      sessionId
      createdAt
      updatedAt
      Session {
        id
        botName
        userId
        assistantId
        threadId
        modelVersion
        createdAt
        updatedAt
        userProfileSessionsId
        __typename
      }
      content
      source
      timeStamp
      sessionChatMessagesId
      __typename
    }
  }
`;
export const deleteChatMessage = /* GraphQL */ `
  mutation DeleteChatMessage(
    $input: DeleteChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    deleteChatMessage(input: $input, condition: $condition) {
      id
      sessionId
      createdAt
      updatedAt
      Session {
        id
        botName
        userId
        assistantId
        threadId
        modelVersion
        createdAt
        updatedAt
        userProfileSessionsId
        __typename
      }
      content
      source
      timeStamp
      sessionChatMessagesId
      __typename
    }
  }
`;
